<template>
    <div>
        <div class="max-w-lg mx-auto px-4 md:px-6 pt-8">
            <TinyNavigation :prev="{name: 'settings'}" />
            <h1 class="text-white font-semibold text-2xl pt-8">{{ t('settings') }}</h1>
            <form @submit.prevent="handleSubmit" class="mt-5">
                <SelectInput v-model:value="form.locale" :options="language_options" :label="t('language')" />

                <div class="grid grid-cols-1 sm:grid-cols-2 gap-4 pt-6">
                    <TextInput v-model:value="form.first_name" v-model:errors="errors.first_name" :alt-styling="true" :label="t('first_name')" :placeholder="t('first_name')" />
                    <TextInput v-model:value="form.last_name" v-model:errors="errors.last_name" :alt-styling="true" :label="t('last_name')" :placeholder="t('last_name')" />
                </div>
                <hr class="my-8 border-gray-600">

                <SelectInput v-model:value="form.position" v-model:errors="errors.position" :options="position_options" :label="t('favorite_position')" />
                <TextInput v-model:value="form.focus" v-model:errors="errors.focus" :label="t('focus')" :alt-styling="true" class="pt-6" />

                <hr class="my-8 border-gray-600">

                <SelectInput v-model:value="form.sex" v-model:errors="errors.sex" :options="sex_options" :label="t('sex')" />
                <TextInput v-model:value="form.club" v-model:errors="errors.club" :alt-styling="true" :label="t('club')" :placeholder="t('club_placeholder')" class="pt-6" />

                <hr class="my-8 border-gray-600">
                
                <ImageInput v-model:value="form.profile_image" v-model:errors="errors.profile_image" :label="t('profile_image')" :url_value="profile_image_url" class="pb-6" />
                <!-- <ImageInput v-model:value="form.club_logo" v-model:errors="errors.club_logo" :label="Club logo" :url_value="club_image_url" /> -->

                <div class="mt-8">
                    <span class="block w-full rounded-md shadow-sm">
                        <button type="submit" class="button w-full" @click.prevent="handleSubmit">
                            {{ t('save') }}
                        </button>
                    </span>
                </div>
            </form>

        </div>
    </div>
</template>

<script>
import { updateProfile } from "@/helpers/api/AccountHelper";
import { startSpinner, stopSpinner } from "@/helpers/storage/loadingSpinnerHelper";
import { indexPositions } from "@/helpers/api/SportHelper";
import TinyNavigation from "@/components/TinyNavigation";
import messages from "@/helpers/storage/MessageHelper";
import SelectInput from "@/components/forms/SelectInput";
import TextInput from "@/components/forms/TextInput";
import ImageInput from "@/components/forms/ImageInput";
import useI18n from "@/modules/globalI18n";
import i18n, { setI18nLanguage } from "../../i18n";

export default {
    name: "ProfileEdit",
    components: { 
        TinyNavigation,
        TextInput,
        ImageInput,
        SelectInput,
    },
    setup() {
        return useI18n({
            viewPrefix: "ProfileEdit"
        });
    },
    created(){
        indexPositions().
            then(sport_positions => {
                this.position_options = sport_positions.map(pos => {return {key: pos.abbreviation, text: pos.name}});
            });

        const user = this.$store.getters['getUser'];

        // set the current values, skip profile_image and club_logo
        for(const key in this.form) {
            if (key in user && key !== "profile_image" && key !== "club_logo"){
                this.form[key] = user[key];
            }
        }

        this.profile_image_url = user.profile_image
        this.club_image_url = user.club_logo
        this.locale = user.locale
    },
    methods:{
        handleSubmit() {
            startSpinner();

            const form = this.form

            updateProfile(form)
                .then(() => {
                    setI18nLanguage(i18n, form.locale)
                    messages.success(this.t('messages.profile_saved'))
                })
                .catch(err => this.errors = err.errors)
                .finally(() => stopSpinner())
        }
    },
    computed: {
        sex_options () {
            return [
                {
                    key: 'other',
                    text: this.t('sex_options.other'),
                },
                {
                    key: 'male',
                    text: this.t('sex_options.male'),
                },
                {
                    key: 'female',
                    text: this.t('sex_options.female'),
                },
            ];
        },
        language_options () {
            return [
                {
                    key: 'en-GB',
                    text: this.t('language_options.en'),
                }, 
                {
                    key: 'nl-NL',
                    text: this.t('language_options.nl'),
                }
            ];
        }
    },
    data(){
        return {
            form: {
                locale: "nl-NL",
                focus: "",
                position: "",
                sex: "",
                club: "",
                club_logo: undefined,
                profile_image: undefined,
                first_name: "",
                last_name: "",
            },
            errors: {
                focus: [],
                position: [],
                sex: [],
                club: [],
                // club_logo: [],
                profile_image: [],
                first_name: [],
                last_name: [],
            },
            profile_image_url: "",
            club_image_url: "",
            position_options: [],
        };
    },
}
</script>